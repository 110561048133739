<template>
  <div class="custom-section agents" id="agents">
    <div class="vx-row">
      <vs-col vs-w="10">
        <!-- title -->
        <h2 class="mb-0">
          {{ lang.metricAgents.title[languageSelected] }}
          <span v-if="total">({{ total }})</span>
        </h2>
      </vs-col>
      <vs-col vs-w="2">
        <!-- download button -->
        <vs-button
          v-tooltip="lang.metricAgents.download[languageSelected]"
          class="float-right"
          size
          radius
          type="flat"
          icon="icon-download"
          icon-pack="feather"
          @click="downloadAll"
        />
      </vs-col>
    </div>

    <!-- table wrapper -->
    <div class="vx-row">
      <vs-col vs-w="12">
        <div
          class="pt-4 pb-base vs-con-loading__container"
          :id="`div-with-loading-${report._id}`"
        >
          <vs-table
            :data="result"
            search
            :sst="true"
            @search="handleSearch"
            @sort="handleSort"
            @change-page="handleChangePage"
            :max-items="LIMIT"
            pagination
            :total="total"
            ref="table"
            :key="key"
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <template slot="thead">
              <vs-th
                :sort-key="sortKey(indexth)"
                :key="indexth"
                v-for="(_, indexth) in report._extra.th"
                v-if="!hiddenColumn(indexth)"
              >
                {{ th(indexth) }}
                <KonaHelpTooltip
                  v-show="isTimeField(indexth)"
                  :text="lang.helpTooltips.metrics.agents[languageSelected]"
                  position="right"
                />
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr
                :key="indextr"
                v-for="(tr, indextr) in data"
                class="bg-white"
              >
                <vs-td :data="data[indextr].user">
                  {{ data[indextr].user.name }}&nbsp;({{
                    data[indextr].user.email
                  }})
                </vs-td>
                <vs-td :data="data[indextr].count">
                  <vs-chip color="primary">{{ data[indextr].count }}</vs-chip>
                </vs-td>
                <!-- <vs-td :data="data[indextr].chats">
                  <span
                    class="truncate"
                    :key="`${indexItem}___`"
                    v-for="(item, indexItem) in data[indextr].showall
                      ? data[indextr].chats
                      : data[indextr].chats.slice(0, LIMIT)"
                  >
                    <vs-chip class="truncate mt-1 mb-1 p-0" color="transparent">
                      <vs-chip>
                        <vs-avatar :text="`${item.times}`" />
                        <a
                          @click="changeView(item.chat)"
                          style="cursor: pointer;"
                        >
                          {{
                            item.fullname ||
                              lang.attentionRequests.sidenav.anonymousUser[
                                languageSelected
                              ]
                          }}
                        </a>
                      </vs-chip>
                      <vs-chip>{{ item.platform }}</vs-chip>
                    </vs-chip>
                  </span>
                  <vs-divider v-if="data[indextr].chats.length > LIMIT" />
                  <div
                    @click="data[indextr].showall = true"
                    class="mb-2 float-right text-primary"
                    style="cursor: pointer;"
                    v-if="
                      data[indextr].chats.length > LIMIT &&
                        !data[indextr].showall
                    "
                  >
                    {{ lang.metrics.showMore[languageSelected] }}
                    ({{ data[indextr].chats.length - LIMIT }})
                  </div>
                  <div
                    @click="data[indextr].showall = false"
                    class="mb-2 float-right text-primary"
                    style="cursor: pointer;"
                    v-if="
                      data[indextr].chats.length > LIMIT &&
                        data[indextr].showall
                    "
                  >
                    {{ lang.metrics.showLess[languageSelected] }}
                  </div>
                </vs-td>-->
                <vs-td :data="data[indextr].resueltos">
                  <vs-chip color="primary">
                    {{ data[indextr].resueltos }}
                  </vs-chip>
                </vs-td>
                <vs-td :data="data[indextr].liberados">
                  <vs-chip color="primary">
                    {{ data[indextr].liberados }}
                  </vs-chip>
                </vs-td>
                <vs-td :data="data[indextr].avgtime">
                  {{ humanizeDur(data[indextr].avgtime) }}
                </vs-td>
                <vs-td :data="data[indextr].avgatttime">
                  {{ humanizeDur(data[indextr].avgatttime) }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { AGENTS_TIME_FIELDS } from '../../../../../constants/constants'
const humanizeDuration = require('humanize-duration')
const Json2csvParser = require('json2csv').Parser
const moment = require('moment')

export default {
  name: 'AgentsTableV1',
  props: {
    report: {
      type: Object,
      required: true
    },
    interval: {
      type: Object,
      required: true
    },
    services: {
      type: Array,
      required: true
    }
  },
  components: {
    VsTable: () => import('../../../../../components/vx-table/vsTable'),
    VsTh: () => import('../../../../../components/vx-table/vsTh'),
    VsTr: () => import('../../../../../components/vx-table/vsTr'),
    VsTd: () => import('../../../../../components/vx-table/vsTd'),
    KonaHelpTooltip: () =>
      import('../../../../../components/KonaHelpTooltip.vue')
  },
  data() {
    return {
      Json2csvParser,

      LIMIT: 25, // cantidad de elementos por página
      key: new Date().getTime(),

      result: [],
      total: null,
      currentPage: 1,

      orderByColumn: 'count',
      orderByOrder: 'asc',

      search: '',
      searchTimeout: null,
      lastSearch: '',
      searchAwait: 700,

      fullnameLike: ''
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    title() {
      const found = this.report.name.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    },
    description() {
      const found = this.report.description.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    }
  },
  watch: {
    services() {
      this.reset()
      this.load(this.report, this.LIMIT, 0)
    },
    interval() {
      this.reset()
      this.load(this.report, this.LIMIT, 0)
    }
  },
  methods: {
    ...mapActions('metrics', ['getReportData']),
    reset() {
      this.result = []
      this.total = 0
      this.currentPage = 1

      this.orderByColumn = 'user'
      this.orderByOrder = 'desc'

      this.search = ''
      this.searchTimeout = null
      this.lastSearch = ''
      this.searchAwait = 700

      this.fullnameLike = ''
    },
    takeoversCount(takeovers, session) {
      return (
        takeovers &&
        takeovers.filter(item => item.sessionId && item.sessionId === session)
          .length
      )
    },
    changeView(chat, session, message) {
      let routeData = this.$router.resolve({
        path: '/attention-requests',
        query: {
          chat,
          session,
          message
        }
      })
      window.open(routeData.href, '_blank')
    },
    getChats(chats) {
      let chatsString = ''

      chats.forEach(chat => {
        const route = this.$router.resolve({
          path: '/attention-requests',
          query: {
            chat: chat.chat
          }
        })
        chatsString = chatsString.concat(
          `${chat.times} ${chat.fullname ||
            this.lang.attentionRequests.sidenav.anonymousUser[
              this.languageSelected
            ]} ${chat.platform} ${window.location.origin + route.href} | `
        )
      })

      return chatsString.replace(/| $/, '')
    },
    async downloadAll() {
      function saveData(blob, fileName) {
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
      }

      if (!this.services || this.services.length === 0) {
        return
      }
      if (!this.interval || !this.interval.from || !this.interval.to) {
        return
      }
      const toDateStr =
        this.interval && moment(this.interval.to).format('YYYY-MM-DD HH:mm:ss')
      const fromDateStr =
        this.interval &&
        moment(this.interval.from).format('YYYY-MM-DD HH:mm:ss')
      const payload = {
        fullnameLike: this.fullnameLike,
        orderByColumn: this.orderByColumn,
        orderByOrder: this.orderByOrder,
        limit: 1000000,
        offset: 0,
        services: this.services,
        from: fromDateStr,
        to: toDateStr
      }
      this.$vs.loading({
        container: `#div-with-loading-${this.report._id}`,
        scale: 1.0
      })
      try {
        const result = await this.getReportData({
          reportId: this.report._id,
          payload
        })
        const aux = result.data[0]

        let fields = this.report._extra.th.map((item, index) => this.th(index))

        // fields[fields.length - 1] = 'Chat.id'
        // fields.push('Chat.times')
        // fields.push('Chat.platform')
        // fields.push('Chat.fullname')

        let data = []

        aux.forEach(item => {
          data.push({})
          data[data.length - 1][
            fields[0]
          ] = `${item.user.name} (${item.user.email})`
          data[data.length - 1][fields[1]] = item.count
          data[data.length - 1][fields[2]] = item.resueltos
          data[data.length - 1][fields[3]] = item.liberados
          data[data.length - 1][fields[4]] = this.humanizeDur(item.avgtime)
          data[data.length - 1][fields[5]] = this.humanizeDur(item.avgatttime)
          data[data.length - 1][fields[6]] = this.getChats(item.chats)

          // data[data.length - 1]['Chating'] = item.chats.map(chat => ({
          //   id: chat.chat,
          //   times: chat.times,
          //   platform: chat.platform,
          //   fullname: chat.fullname
          // }))

          // const route = this.$router.resolve({
          //   path: '/attention-requests',
          //   query: {
          //     chat: item.chat,
          //     session: item.sessionid,
          //     message: item._firstmessageid
          //   }
          // })
        })

        const json2csvParser = new Json2csvParser({ fields })
        const csv = json2csvParser.parse(data)

        let filename = `konecta_metric_${this.title}_`
        filename += new Date().toISOString()

        var BOM = '\uFEFF'
        var csvData = BOM + csv

        var blob = new Blob([csvData], {
          type: 'text/plain; encoding=utf-8'
        })

        saveData(blob, `${filename}.csv`)
        this.$vs.loading.close(
          `#div-with-loading-${this.report._id} > .con-vs-loading`
        )
      } catch (ex) {
        if (ex.message !== 'cancelled') {
          this.$vs.loading.close(
            `#div-with-loading-${this.report._id} > .con-vs-loading`
          )
          const reportName =
            this.languageSelected === 'es'
              ? this.report.name[0].text
              : this.report.name[1].text
          this.$vs.notify({
            text:
              this.lang.metrics.reportMessages.text[this.languageSelected] +
              `"${reportName}"`,
            color: 'danger'
          })
          console.error(ex)
        }
      }
    },
    // async download(session) {
    //   var doc = new jsPDF()

    //   let pic_height

    //   if (session.picture) {
    //     const el = this.$refs['avatar'][0]

    //     const options = {
    //       type: 'dataURL'
    //     }
    //     const output = await this.$html2canvas(el, options)

    //     const width = doc.internal.pageSize.getWidth()

    //     const componentWidth = this.$refs['avatar'][0].clientWidth
    //     const percent = (width * 100) / componentWidth
    //     const height = this.$refs['avatar'][0].clientHeight * percent * 0.01
    //     pic_height = height

    //     doc.addImage(output, 'PNG', 0, 10, width, height)
    //   }

    //   let name =
    //     session.fullname ||
    //     this.lang.attentionRequests.sidenav.anonymousUser[this.languageSelected]

    //   name = emojiStrip(name)

    //   const route = this.$router.resolve({
    //     path: '/attention-requests',
    //     query: {
    //       chat: session.chat,
    //       session: session.sessionid,
    //       message: session._firstmessageid
    //     }
    //   })

    //   const link = window.location.origin + route.href

    //   doc.fromHTML(
    //     `
    //       <h1>
    //         ${name}
    //       </h1>
    //       <p>
    //         ${
    //           this.lang.metricSessions.export.platform[this.languageSelected]
    //         }: ${session.platform}
    //       </p>
    //       <hr/>
    //       <p>
    //       ${
    //         this.lang.metricSessions.export.duration[this.languageSelected]
    //       }: ${this.$moment
    //       .duration(
    //         new Date(session._lastmessageat) - new Date(session._firstmessageat)
    //       )
    //       .humanize()}
    //       </p>
    //       <p>
    //       ${this.$moment(new Date(session._firstmessageat)).format(
    //         'DD-MM-YYYY, h:mm:ss A'
    //       )} / ${this.$moment(new Date(session._lastmessageat)).format(
    //       'DD-MM-YYYY, h:mm:ss A'
    //     )}
    //       </p>
    //       <br/>
    //       <br/>
    //       <br/>
    //       <p>
    //       ${this.lang.metricSessions.export.derived[this.languageSelected]}: ${
    //       session.derived
    //         ? this.lang.metricSessions.export.yes[this.languageSelected]
    //         : this.lang.metricSessions.export.no[this.languageSelected]
    //     }
    //       </p>
    //       <p>
    //       ${
    //         this.lang.metricSessions.export.takeovers[this.languageSelected]
    //       }: ${this.takeoversCount(session.takeovers, session.sessionid)}
    //       </p>
    //       <strong>
    //       ${this.lang.metricSessions.export.messages[this.languageSelected]} (${
    //       session.messagescount
    //     })
    //       </strong>
    //       <ul>
    //       <li>
    //       ${this.lang.metricSessions.agentMessages[this.languageSelected]}: ${
    //       session.agentmessagescount
    //     }
    //       </li>
    //       <li>
    //       ${this.lang.metricSessions.userMessages[this.languageSelected]}: ${
    //       session.usermessagescount
    //     }
    //       </li>
    //       <li>
    //       ${this.lang.metricSessions.botResponses[this.languageSelected]}: ${
    //       session.botmessagescount
    //     }
    //       </li>
    //       </ul>
    //     `,
    //     10,
    //     (pic_height || 0) + 10
    //   )

    //   doc.textWithLink('Link', 10, doc.internal.pageSize.getHeight() - 120, {
    //     url: link
    //   })

    //   doc.save(`session_${session.sessionid}.pdf`)
    // },
    th(index) {
      if (!this.report || !this.report._extra || !this.report._extra.th) {
        return 'N/A'
      }
      if (this.report._extra.th[index]) {
        const found = this.report._extra.th[index].find(element => {
          return element.lang === this.languageSelected
        })
        if (found) {
          return found.text
        }
      }
      return 'N/A'
    },
    sortKey(index) {
      if (!this.report || !this.report._extra || !this.report._extra.th) {
        return undefined
      }
      if (this.report._extra.th[index]) {
        const found = this.report._extra.th[index].find(element => {
          return element.lang === this.languageSelected
        })
        if (found) {
          return found.sortKey
        }
      }
      return undefined
    },
    humanizeDur(aux) {
      return humanizeDuration(aux, {
        round: true,
        largest: 2,
        language: this.languageSelected
      })
    },
    handleSort(column, order) {
      if (!order) {
        return
      }
      this.orderByColumn = column
      this.orderByOrder = order
      this.load(this.report, this.LIMIT, 0, this.currentPage !== 1)
    },
    handleChangePage(page) {
      // quitamos los elementos que estaban abiertos, para que no se rompa
      this.$el.querySelectorAll('.tr-expand').forEach(e => {
        if (e.parentNode) {
          e.parentNode.removeChild(e)
        }
      })
      this.currentPage = page
      if (Math.ceil(this.result.length / this.LIMIT) <= page) {
        this.load(this.report, this.LIMIT, this.result.length)
      } else {
        // workaround para hacer que actualize cuando vamos hacia atrás
        const aux = this.result
        this.result = []
        this.$nextTick(() => {
          this.result = aux
          this.currentPage = page
        })
      }
    },
    handleSearch(arg1) {
      this.search = (arg1 || '').trim()
      if (this.search !== this.lastSearch) {
        this.searchTimeout = new Date(Date.now() + this.searchAwait)
      } else {
        this.searchTimeout = null
      }
    },
    async load(report, limit, offset, reset) {
      if (!this.services || this.services.length === 0) {
        return
      }
      if (!this.interval || !this.interval.from || !this.interval.to) {
        return
      }
      const toDateStr =
        this.interval && moment(this.interval.to).format('YYYY-MM-DD HH:mm:ss')
      const fromDateStr =
        this.interval &&
        moment(this.interval.from).format('YYYY-MM-DD HH:mm:ss')
      const data = {
        fullnameLike: this.fullnameLike,
        orderByColumn: this.orderByColumn,
        orderByOrder: this.orderByOrder,
        limit,
        offset,
        services: this.services,
        from: fromDateStr,
        to: toDateStr
      }
      this.$vs.loading({
        container: `#div-with-loading-${report._id}`,
        scale: 1.0
      })
      try {
        const result = await this.getReportData({
          reportId: this.report._id,
          payload: data
        })
        if (offset === 0) {
          this.result = []
        }
        if (reset) {
          this.key = new Date().getTime()
          this.currentPage = 1
        }
        this.result = this.result.concat(result.data[0])
        this.total = result.data[0].length

        this.$vs.loading.close(
          `#div-with-loading-${report._id} > .con-vs-loading`
        )
      } catch (ex) {
        if (ex.message !== 'cancelled') {
          this.$vs.loading.close(
            `#div-with-loading-${report._id} > .con-vs-loading`
          )
          const reportName =
            this.languageSelected === 'es'
              ? report.name[0].text
              : report.name[1].text
          this.$vs.notify({
            text:
              this.lang.metrics.reportMessages.text[this.languageSelected] +
              `"${reportName}"`,
            color: 'danger'
          })
          console.error(ex)
        }
      }
    },
    isTimeField(index) {
      return AGENTS_TIME_FIELDS.includes(this.report._extra.th[index][1].text)
    },
    hiddenColumn(index) {
      return this.report._extra.th[index][1].text === 'Chats'
    }
  },
  async mounted() {
    setInterval(() => {
      if (this.searchTimeout && this.searchTimeout < new Date()) {
        this.searchTimeout = null
        this.lastSearch = this.search
        this.fullnameLike = this.search
        this.load(this.report, this.LIMIT, 0, this.currentPage !== 1)
      }
    }, 200)
  }
}
</script>

<style>
.vs-table--tbody {
  z-index: 1 !important;
}
</style>

<style scoped>
.center {
  margin: auto;
  width: 100%;
}
</style>

<style lang="scss">
.agents {
  margin: 30px 0px;
  padding: 20px;
  background-color: #fafafa;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  &__title {
    display: flex;
    align-items: center;
  }

  .error-message {
    display: block;
    height: 19px;
    color: rgba(var(--vs-danger), 1);
    font-size: 0.65rem;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .btn-actions {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
